const Path = ({ isOpen, ...props }) => (
  <path
    fill="transparent"
    strokeWidth="2"
    stroke={!isOpen ? "black": "white" }
    strokeLinecap="round"
    {...props}
  />
);




export const MenuIcon = ({ toggle, isOpen, handleClick }) => {
 

  return (
    <button
      className={`menu-button menu-toggle-${!isOpen ? "isClose" : "isOpen"}`}
      onClick={() => {
        toggle();
        handleClick();
      }}
    >
      <svg width="40" height="35" viewBox="0 0 23 18">
        <Path d="M 2 2.5 L 20 2.5" className="top" isOpen={isOpen}/>
        <Path d="M 2 9.423 L 20 9.423" opacity="1" className="middle" />
        <Path d="M 2 16.346 L 20 16.346" className="bottom" isOpen={isOpen} />
      </svg>
    </button>
  );
};
