// import logo from './logo.svg';
import "./App.css";
import { motion, useScroll } from "framer-motion";
import Router from "./components/router";
import PageProvider from "./context/isOpenContext"


function App() {
  const { scrollYProgress } = useScroll();
  
  
  return (
    <PageProvider>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <Router/> 
    </PageProvider>
  );
}

export default App;
